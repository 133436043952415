<template>
  <div class="pd-20">
    <aPageHeader class="pd-0" sub-title="">
      <template #title>
        <h1>
          <img src="@/assets/images/agreement.png" alt="ico" /> Relatório de
          Embarques
        </h1>
      </template>

      <template #extra>
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="contract.list"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img
            class="c-pointer"
            src="@/assets/images/dashboard/excel.png"
            alt="excel"
          />
        </downloadExcel>
      </template>
    </aPageHeader>

    <aCollapse
      class="travel-filters expandable mt-0 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <aCollapsePanel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <aRow class="mt-0 mb-0" :gutter="20">
          <aCol :span="2">
            <div class="travel-input">
              <label for class="filled">ID</label>
              <a-input
                placeholder="Insira o ID"
                v-model="contract.filters.id"
                @change="getContracts()"
              />
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label for class="filled">Contratante </label>
              <a-auto-complete
                :data-source="
                  customers.list.map(({ first_name, last_name, id }) => ({
                    value: id,
                    text: `${id} - ${first_name} ${last_name}`,
                  }))
                "
                v-model="customers.filters.searchTerm"
                style="width: 100%; height: 32px"
                placeholder="Buscar contratantes..."
                @change="
                  customers.filters.searchTerm ? getCustomers() : getContracts()
                "
                @select="customerSelected"
              />
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label for class="filled">Tipo de Vôo</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um tipo"
                mode="multiple"
                v-model="contract.filters.flightType.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of contract.filters.flightType.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol :span="4">
            <div class="travel-input">
              <label for class="filled">Categorias do Produto</label>
              <a-select
                class="travel-input"
                placeholder="Selecione uma categoria"
                mode="multiple"
                v-model="contract.filters.productsCategory.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of contract.filters.productsCategory
                    .list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label for class="filled">Embarque</label>
              <a-range-picker
                v-model="contract.filters.checkin.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label for class="filled">Checkout</label>
              <a-range-picker
                v-model="contract.filters.checkout.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </aCol>

          <aCol :span="4">
            <div class="travel-input">
              <label for class="filled">Empresas</label>
              <a-select
                class="travel-input"
                placeholder="Selecione uma empresa"
                mode="multiple"
                v-model="contract.filters.companies.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of contract.filters.companies.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol v-if="$root.isAdmin()" :span="4">
            <div class="travel-input">
              <label for class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                mode="multiple"
                v-model="contract.filters.users.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of contract.filters.users.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>
        </aRow>
      </aCollapsePanel>
    </aCollapse>

    <a-table
      class="travel-table"
      :columns="columns"
      :data-source="contract.list"
      :loading="contract.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="contractTableChange"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <a slot="contractor" slot-scope="text">{{ text }}</a>
      <div slot="customer" slot-scope="record">
        <div
          v-if="record.customer.person_type === 'Pessoa Física'"
          class="dotted-phrase upper"
        >
          {{ record.customer.id }} - {{ record.customer.first_name }}
          {{ record.customer.last_name }}
        </div>

        <div
          v-if="record.customer.person_type === 'Pessoa Jurídica'"
          class="dotted-phrase upper"
        >
          {{ record.customer.id }} - {{ record.customer.trading_name }}
        </div>
      </div>
      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="gettheAvatar(record.user.id)"
              :src="gettheAvatar(record.user.id)"
              :size="30"
            />
            <a-avatar v-else style="color: #f56a00; background-color: #fde3cf">
              {{ record.user.first_name }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>
      <template slot="product" slot-scope="record">
        <a-tooltip
          class="travel-tooltip"
          placement="top"
          style="display: inline-block"
        >
          <template slot="title">
            <div v-html="formatProductsTxt(record)" />
          </template>
          <a-tag
            v-for="(product, index) in formatProductsList(
              record.contracted_services
            )"
            :key="index"
            :color="product.color"
          >
            <div v-html="product.name" />
          </a-tag>
        </a-tooltip>
      </template>
      <div slot="contact" slot-scope="record">
        {{
          record.customer
            ? record.customer.mobile_phone
            : record.meta.client_mobile_phone
        }}
      </div>
      <div slot="checkin" slot-scope="record">

        <div v-if="record.first_checkin">
          {{
            record.first_checkin == "0000-00-00"
              ? formatMultiDates(record.meta.contract_first_checkin)
              : formatMultiDates(record.first_checkin)
          }}
        </div>
        <div v-else>----</div>
      </div>
      <div slot="checkout" slot-scope="record">
        <div v-if="record.last_checkout">
          {{
            record.last_checkout == "0000-00-00"
              ? formatMultiDates(record.meta.contract_last_checkout)
              : formatMultiDates(record.last_checkout)
          }}
        </div>
        <div v-else>----</div>
      </div>
      <div slot="created" slot-scope="record">
        {{ formatDateTime(record.created) }}
      </div>
      <div slot="company" slot-scope="record">
        {{ record.company_details.trading_name ?? record.company }}
      </div>
      <div slot="passengers" slot-scope="record">
        {{ formatPassengers(record) }}
      </div>
      <div slot="payments" slot-scope="record">
        <a-tag
          class="ant-tag-price"
          v-for="(payment, index) in record"
          :key="index"
        >
          <span v-html="payment.name" />
        </a-tag>
      </div>

      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Ver">
            <a class="view ml-15" @click="view(record.id)">
              <a-icon
                slot="prefix"
                :type="record.contract_done ? 'green-eye-svg' : 'eye-svg'"
              />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="contract.pagination.page"
        :page-size-options="pageSizeOptions"
        :page-size.sync="contract.pagination.perPage"
        :total="contract.pagination.total"
        @change="changeContractsPage"
        @showSizeChange="changeContractsPageSize"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '99999'">{{ props.value }}/página</span>
          <span v-if="props.value === '99999'"> Todos</span>
        </template>
      </a-pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { format } from "date-fns";

// mixins
import customerMixins from "@/mixins/customers/customerMixins";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ContractDeparturesReport",
  mixins: [customerMixins, contractMixins, formatThings],
  data() {
    return {
      excelFile: {
        header: "Relatório Embarques",
        fileName: "Relatório Embarques - Contratos.xls",
        collumns: {
          ID: "id",
          Contrante: {
            callback: (contract) => {
              return this.theContractor(contract);
            },
          },
          Contato: {
            callback: (contract) => {
              return `${
                contract.customer
                  ? contract?.customer?.mobile_phone
                  : contract?.meta?.client_mobile_phone
              } `;
            },
          },
          Produtos: {
            callback: (contract) => {
              return this.formatProductsTxt(contract);
            },
          },
          Empresa: "company",
          Checkin: {
            callback: (contract) => {
              return `${this.formatCheckin(contract)} `;
            },
          },
          Checkout: {
            callback: (contract) => {
              return `${this.formatCheckout(contract)} `;
            },
          },
          Passageiros: {
            callback: (contract) => {
              return `${this.formatPassengers(contract)} `;
            },
          },
          Usuário: {
            field: "user",
            callback: (user) => {
              return `${user.first_name} ${user.last_name} `;
            },
          },
          "Data/Hora": "created",
        },
        data: [],
        footer: [],
      },
      tempContract: {},
      pageSizeOptions: ["10", "25", "50", "100", "200", "500", "1000"],

      contractList: [],
      totalSold: 0,
      infoteraData: [],
      form: this.$form.createForm(this),
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 70,
          sorter: true,
        },
        {
          title: "Contratante",
          scopedSlots: { customRender: "customer" },
          key: "customer_id",
          width: 200,
        },
        {
          title: "Contato",
          key: "contact",
          width: 140,
          scopedSlots: { customRender: "contact" },
        },
        {
          title: "Produtos",
          scopedSlots: { customRender: "product" },
          width: 160,
        },
        {
          title: "Empresa",
          scopedSlots: { customRender: "company" },
          sorter: true,
          width: 120,
        },
        {
          title: "Check-in",
          key: "first_checkin",
          scopedSlots: { customRender: "checkin" },
          sorter: true,
        },
        {
          title: "Check-out",
          key: "last_checkout",
          scopedSlots: { customRender: "checkout" },
          sorter: true,
        },
        {
          title: "Passageiros",
          key: "passengers",
          scopedSlots: { customRender: "passengers" },
        },
        {
          title: "Usuário",
          scopedSlots: { customRender: "user" },
        },
        {
          title: "Data/Hora",
          scopedSlots: { customRender: "created" },
          key: "created",
          sorter: true,
          width: 160,
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 80,
          fixed: this.$root.isAdmin() ? false : "right",
          scopedSlots: { customRender: "action" },
        },
      ],

      loading: false,
      theContract: [],
      usersList: [],
    };
  },
  beforeMount() {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      firstDay = new Date(y, m, 1),
      lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.contract.filters.status.selected = "concluded";
    this.contract.filters.checkin.selected = [`${firstDay}`, `${lastDay}`];

    this.contract.filters.order = "ascend";
    this.contract.filters.orderBy = "first_checkin ASC, first_checkin2 ";

    this.getContracts();
  },
  computed: {
    routeTab() {
      return {
        title: `Relatório de Embarques - Contratos`,
        icon: "line-chart",
        tips: "",
      };
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    formatCheckin(contract) {
      let theDate = "";
      let services = contract.contracted_services
        ? JSON.parse(contract.contracted_services)
        : [];

      console.log("formatCheckin", contract);

      if (services.includes("hotel"))
        theDate = this.formatMultiDates(contract.meta.hotel_1_checkin);

      if (services.includes("flight"))
        theDate = this.formatMultiDates(
          contract.meta.flight_1_section_1_departure_date
        );

      console.log();

      return theDate ? theDate : "Não selecionado";
    },

    formatCheckout(contract) {
      let theDate = "";

      let services = contract.contracted_services
        ? JSON.parse(contract.contracted_services)
        : [];
      let flights = [];

      if (services.includes("hotel")) {
        let hotels = JSON.parse(contract.meta.hotel_rows),
          totalFlights = hotels.length;
        theDate = this.formatMultiDates(
          contract.meta[`hotel_${totalFlights}_checkout`]
        );
      }

      if (services.includes("flight")) {
        flights = JSON.parse(contract.meta.flight_sections_rows);
        let totalFlights = flights.length,
          lastSection = flights[totalFlights - 1].sections.length;

        theDate = this.formatMultiDates(
          contract.meta[
            `flight_${totalFlights}_section_${lastSection}_arrival_date`
          ]
        );
      }

      return theDate ? theDate : "Não selecionado";
    },
    formatPassengers(contract) {
      let travellers = contract.meta?.travellers_rows
        ? JSON.parse(contract.meta?.travellers_rows)
        : [];
      return travellers.length;
    },
    customerSelected(customer) {
      this.customers.filters.searchTerm = customer;
      this.filters.customer = customer;

      this.getContracts();
    },

    theContractor(contract) {
      let name = "NÃO INFORMADO";

      if (contract.customer == false) {
        if (contract.meta.client_name != undefined) {
          name = `${contract.meta.client_name.toUpperCase()}`;
        }
      } else {
        if (contract.customer.person_type == "Pessoa Física") {
          name = `${contract.customer.first_name.toUpperCase()} ${contract.customer.last_name.toUpperCase()}`;
        } else {
          name = `${contract.customer.trading_name.toUpperCase()} `;
        }
      }

      return name;
    },
    gettheAvatar(id) {
      let theuser = this.usersList.filter((user) => {
        return user.id == id;
      });
      return theuser.length > 0 ? theuser[0].avatar : "";
    },
    getUsers() {
      this.$http
        .get("/user/list?page=1&per_page=100&user_id=1")
        .then(({ data }) => {
          this.usersList = data.data;
          this.filters.users.list = data.data.map((user) => {
            return {
              label: `${user.id} - ${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    formatPrice(price) {
      return price
        ? `<span class="ant-tag ant-tag-price">${Number(
            parseFloat(price.replace(",", ".")).toFixed(2)
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}</span>`
        : `---`;
    },
    search: _.debounce(function (e) {
      this.loading = true;
      this.$http
        .post("/contract/search?s=" + e.target.value)
        .then(({ data }) => {
          let filteredContracts = [];

          if (!this.$root.isAdmin() && !this.$root.isAnalyst()) {
            data.data.forEach((contract) => {
              if (
                contract.user.email == this.$store.state.userData.email ||
                contract.meta.client_consultant ==
                  this.$store.state.userData.email
              ) {
                //
                filteredContracts.push(contract);
              }
            });
          } else {
            filteredContracts = data.data;
          }

          this.contractList = filteredContracts.map((contract) => {
            return {
              id: contract.id,
              contractor: this.theContractor(contract),
              product:
                this.formatProducts(contract.meta) != "UNDEFINED"
                  ? this.formatProducts(contract.meta)
                  : "NÃO INFORMADO",
              payments:
                this.formatPayments(contract.meta) != "UNDEFINED"
                  ? this.formatPayments(contract.meta)
                  : "NÃO INFORMADO",
              totalValue: this.formatPrice(
                contract.meta.reservation_total_price
              ),
              company: contract.company,
              user: `${contract.user.first_name} ${contract.user.last_name} `,
              status: contract.status,
              changeHistory: contract.update_logs,
              created: this.formatDateTime(contract.created),
              contract_done:
                contract.meta["contract_done"] != undefined
                  ? `${contract.meta.contract_done}`
                  : false,
              theContract: contract,
              paymentsExcel:
                this.formatPaymentsTxt(contract.meta) != "UNDEFINED"
                  ? this.formatPaymentsTxt(contract.meta)
                  : "NÃO INFORMADO",
            };
          });
        })
        .catch(() => {
          this.contractList = [];
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }, 600),
    formatPaymentsTxt(meta) {
      let txt = "";
      if (meta.payment_methods != undefined) {
        JSON.parse(meta.payment_methods).forEach((payment) => {
          if (payment == "credit-card") {
            txt += "Cartão de Crédito,";
          }

          if (payment == "billing-ticket") {
            txt += "Boleto,";
          }

          if (payment == "payment-card") {
            txt += "Carta de Pagamento,";
          }

          if (payment == "custom-payment") {
            txt += `${meta.payment_methods_custom_payment_name}*,`;
          }

          if (payment == "bank-transfer") {
            txt += "Transferência Bancária,";
          }

          if (payment == "payment-link") {
            txt += "Link de Pagamento,";
          }
        });
      }
      return txt;
    },
    formatPayments(meta) {
      let allPayments = [];

      if (meta.payment_methods != undefined) {
        JSON.parse(meta.payment_methods).forEach((payment) => {
          if (payment == "credit-card") {
            allPayments.push({
              name: "Cartão de Crédito",
              color: "orange",
            });
          }

          if (payment == "billing-ticket") {
            allPayments.push({
              name: "Boleto",
              color: "purple",
            });
          }

          if (payment == "payment-card") {
            allPayments.push({
              name: "Carta de Pagamento",
              color: "blue",
            });
          }

          if (payment == "custom-payment") {
            allPayments.push({
              name: ` <span class="custom-payment"> ${meta.payment_methods_custom_payment_name}* </span>`,
              color: "blue",
            });
          }

          if (payment == "bank-transfer") {
            allPayments.push({
              name: "Transferência Bancária",
              color: "blue",
            });
          }

          if (payment == "payment-link") {
            allPayments.push({
              name: "Link de Pagamento",
              color: "orange",
            });
          }
        });
      }

      return allPayments;
    },
    formatProductsTxt(contract) {
      let txt = "";

      if (contract.contracted_services) {
        JSON.parse(contract.contracted_services).forEach((p) => {
          // HOTEL
          if (p == "hotel") {
            JSON.parse(contract.meta.hotel_rows).forEach((row) => {
              if (contract.meta[`hotel_${row.id}_name`] != undefined) {
                txt += contract.meta[`hotel_${row.id}_name`]
                  ? contract.meta[`hotel_${row.id}_name`] + "<br>"
                  : `HOTEL ${row.id} <br>`;
              }
            });
          }

          // AÉREO
          if (p == "flight") {
            if (
              contract.meta.flight_rows != undefined &&
              contract.meta.flight_sections_rows == undefined
            ) {
              JSON.parse(contract.meta.flight_rows).forEach((row, index) => {
                if (index == 0) {
                  txt += `AÉREO - ${
                    contract.meta[`flight_${row.id}_class`]
                  } - SAÍDA: ${contract.meta[`flight_${row.id}_origin`]} <br>`;
                }
              });
            }

            if (contract.meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(contract.meta.flight_sections_rows).forEach(
                (flight) => {
                  flight.sections.forEach((section, index) => {
                    type = ` (${
                      contract.meta[
                        `flight_${flight.id}_section_${section.id}_type`
                      ]
                    }) `;

                    if (type == " (undefined) ") {
                      type = "";
                    }

                    if (index == 0) {
                      txt += `AÉREO ${flight.id}  ${type} - ${
                        contract.meta[
                          `flight_${flight.id}_section_${section.id}_class`
                        ] != undefined
                          ? contract.meta[
                              `flight_${flight.id}_section_${section.id}_class`
                            ]
                          : "CLASSE"
                      } - ${
                        contract.meta[
                          `flight_${flight.id}_section_${section.id}_origin`
                        ] != undefined
                          ? contract.meta[
                              `flight_${flight.id}_section_${section.id}_origin`
                            ]
                          : "ORIGEM"
                      } ✈ ${
                        contract.meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ] != undefined
                          ? contract.meta[
                              `flight_${flight.id}_section_${section.id}_destination`
                            ]
                          : "DESTINO"
                      } <br> `;
                    }

                    if (
                      index == flight.sections.length - 1 &&
                      flight.sections.length > 1
                    ) {
                      txt += `AÉREO ${flight.id} ${type} - ${
                        contract.meta[
                          `flight_${flight.id}_section_${section.id}_class`
                        ] != undefined
                          ? contract.meta[
                              `flight_${flight.id}_section_${section.id}_class`
                            ]
                          : "CLASSE"
                      } - ${
                        contract.meta[
                          `flight_${flight.id}_section_${section.id}_origin`
                        ] != undefined
                          ? contract.meta[
                              `flight_${flight.id}_section_${section.id}_origin`
                            ]
                          : "ORIGEM"
                      } ✈  ${
                        contract.meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ] != undefined
                          ? contract.meta[
                              `flight_${flight.id}_section_${section.id}_destination`
                            ]
                          : "DESTINO"
                      } <br>`;
                    }
                  });
                }
              );
            }
          }

          // SERVIÇOS

          if (p == "service") {
            JSON.parse(contract.meta.service_rows).forEach((row) => {
              if (contract.meta[`service_${row.id}_name`] != undefined) {
                txt += contract.meta[`service_${row.id}_name`];
              }
            });
          }
        });
      }

      return txt.toUpperCase();
    },
    formatProductsList(contractedServices) {
      let allProducts = [];

      if (contractedServices) {
        JSON.parse(contractedServices).forEach((product) => {
          if (product == "hotel") {
            allProducts.push({
              color: "green",
              name: "HOTEL",
            });
          }

          if (product == "flight") {
            allProducts.push({
              color: "blue",
              name: "AÉREO",
            });
          }

          if (product == "service") {
            allProducts.push({
              color: "orange",
              name: "SERVIÇO",
            });
          }
        });
      }

      return allProducts;
    },
    formatProducts(meta) {
      let allProducts = [];

      if (meta.contracted_services) {
        JSON.parse(meta.contracted_services).forEach((p) => {
          // HOTEL
          if (p == "hotel") {
            JSON.parse(meta.hotel_rows).forEach((row) => {
              if (meta[`hotel_${row.id}_name`] != undefined) {
                allProducts.push({
                  color: "green",
                  name: meta[`hotel_${row.id}_name`]
                    ? meta[`hotel_${row.id}_name`]
                    : `HOTEL ${row.id}`,
                });
              }
            });
          }

          // AÉREO
          if (p == "flight") {
            if (
              meta.flight_rows != undefined &&
              meta.flight_sections_rows == undefined
            ) {
              JSON.parse(meta.flight_rows).forEach((row, index) => {
                row;

                if (index == 0) {
                  allProducts.push({
                    color: "blue",
                    name: `AÉREO - ${meta[`flight_${row.id}_class`]} - SAíDA: ${
                      meta[`flight_${row.id}_origin`]
                    }`,
                  });
                }
              });
            }

            if (meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(meta.flight_sections_rows).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  type = ` (${
                    meta[`flight_${flight.id}_section_${section.id}_type`]
                  }) `;

                  if (type == " (undefined) ") {
                    type = "";
                  }

                  if (index == 0) {
                    allProducts.push({
                      color: "blue",
                      name: `AÉREO ${flight.id}  ${type} - ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_class`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_class`
                            ]
                          : "CLASSE"
                      } - ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_origin`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_origin`
                            ]
                          : "ORIGEM"
                      } <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i>  ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_destination`
                            ]
                          : "DESTINO"
                      }`,
                    });
                  }

                  if (
                    index == flight.sections.length - 1 &&
                    flight.sections.length > 1
                  ) {
                    allProducts.push({
                      color: "blue",
                      name: `AÉREO ${flight.id} ${type} - ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_class`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_class`
                            ]
                          : "CLASSE"
                      } - ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_origin`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_origin`
                            ]
                          : "ORIGEM"
                      } <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i>  ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ] != undefined
                          ? meta[
                              `flight_${flight.id}_section_${section.id}_destination`
                            ]
                          : "DESTINO"
                      }`,
                    });
                  }
                });
              });
            }
          }

          // SERVIÇOS

          if (p == "service") {
            JSON.parse(meta.service_rows).forEach((row) => {
              if (meta[`service_${row.id}_name`] != undefined) {
                allProducts.push({
                  color: "orange",
                  name: meta[`service_${row.id}_name`],
                });
              }
            });
          }
        });
      }

      return allProducts.length > 0
        ? allProducts
        : [{ color: "", name: "SEM PRODUTOS" }];
    },

    view(id) {
      if (parseInt(id) > 1485) {
        window.open(`/contracts/view/${id}`, "_blank");
      } else {
        window.open(`/contracts/view/${id}`, "_blank");
      }
    },
  },
};
</script>

<style lang="sass">
.ant-tooltip
  max-width: 600px !important
</style>

<style lang="sass" scoped>
#avatar
  opacity: 0.7
  transition: .3s
#avatar:hover
  opacity: 1
</style>
